import { Button, Stack } from '@mui/material';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
export default function BotaoAcao(props) {
  const { status, confirma } = props;
  let color;
  let title;
  let icon;

  switch (status) {
    case 'NÃO':
      color = 'warning';
      title = 'Aprovar';
      icon = <CheckCircleOutlineIcon />;
      break;
    case 'SIM':
      color = 'success';
      title = 'Aprovado';
      icon = <DoneAllIcon />;
      break;

    default:
      color = 'primary';
      title = 'Sem Status';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        variant="contained"
        color={color}
        startIcon={icon}
        disabled={title === 'Aprovar' ? false : true}
        fullWidth
        onClick={confirma}
      >
        {title}
      </Button>
    </Stack>
  );
}
