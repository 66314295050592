import React from 'react';

import { useEffect, useState } from 'react';

import api from '../../api/api.js';
import Spinner from '../../components/Spinner/Spinner.jsx';
import { toast } from 'react-toastify';

import {
  Stack,
  TextField,
  Collapse,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function ContasRecebidas() {
  const [loading, setLoading] = useState(true);
  const [listaAreceber, setListaAReceber] = useState();
  const [totalGeral, setTotalGeral] = useState();

  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-01'));
  const [dataFinal, setDataFinal] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );

  useEffect(() => {
    setLoading(true);
    async function searchData() {
      await api
        .post('/financeiro/contasAReceber', {
          data_inicio: dataInicial,
          data_fim: dataFinal,
          codigo_cliente: 0,
        })
        .then(({ data }) => {
          setListaAReceber(data);
          const valorInicial = 0;
          const somaTotal = data.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.valor_acrescentar,
            valorInicial
          );
          setTotalGeral(somaTotal);
        })
        .catch(() => {
          toast.error('Erro ao buscar dados ');
        })
        .finally(() => setLoading(false));
    }
    searchData();
  }, [dataInicial, dataFinal]);

  function Details(props) {
    const [openDetails, setOpenDetails] = useState(false);
    const { cliente, empresa, tipoConta, numeroConta } = props;

    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: 0,
          }}
        >
          <IconButton onClick={() => setOpenDetails(!openDetails)}>
            {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        <Collapse in={openDetails} unmountOnExit timeout="auto">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              borderBottom: 'solid 0.3px #565656',
            }}
          >
            <Box width={'100%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Cliente</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{cliente}</Typography>
              </Box>
            </Box>
            <Box width={'30%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Empresa</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{empresa}</Typography>
              </Box>
            </Box>

            <Box width={'30%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Tipo</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{tipoConta}</Typography>
              </Box>
            </Box>
            <Box width={'30%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Doc</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{numeroConta}</Typography>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </>
    );
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Stack spacing={2} mb={1.5}>
        <Typography variant="h5">Contas à Receber</Typography>
      </Stack>
      <Stack component="form" noValidate spacing={3} mb={1}>
        <TextField
          id="date"
          label="Data Inicial"
          type="date"
          defaultValue={dataInicial}
          onChange={(e) => setDataInicial(e.target.value)}
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="date"
          label="Data Final"
          type="date"
          size="small"
          defaultValue={dataFinal}
          onChange={(e) => setDataFinal(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="total"
          label="TotalGeral"
          type="text"
          size="small"
          defaultValue={totalGeral.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <Stack sx={{ maxHeight: '350px', overflowY: 'auto' }}>
        {listaAreceber.length > 0 ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                position: 'fixed',
                background: '#565656',
                color: '#ffffff',
                zIndex: 999,
                justifyContent: 'right',
                borderRadius: '4px',
              }}
            >
              <Box
                width={'30vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Vcto</Typography>
              </Box>
              <Box
                width={'25vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Valor</Typography>
              </Box>
              <Box
                width={'22vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Cliente</Typography>
              </Box>
            </Box>
            <Box height={45} marginBottom={5}></Box>
            {listaAreceber.map((areceber, index) => {
              const arrPrimeiroNome = areceber.razao_social.split(' ');

              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'Wrap',
                  }}
                >
                  <Box
                    width={'35%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box>
                      <Typography variant="caption">
                        {moment(areceber.emissao)
                          .add(3, 'hours')
                          .format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'35%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box>
                      <Typography variant="caption">
                        {areceber.valor_acrescentar.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'20%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                      <Typography variant="caption">
                        {arrPrimeiroNome[0]}
                      </Typography>
                    </Box>
                  </Box>
                  <Details
                    cliente={areceber.razao_social}
                    empresa={areceber.empresa}
                    tipoConta={areceber.tipo_conta}
                    numeroConta={areceber.numero_conta}
                  />
                </Box>
              );
            })}
          </>
        ) : (
          <Typography variant="h6">Nenhum registro encontrado!</Typography>
        )}
      </Stack>
    </>
  );
}

export default ContasRecebidas;
