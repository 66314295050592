export const columns = [
  {
    field: 'codigo',
    headerName: 'Codigo',
    width: 75,
  },
  {
    field: 'nome',
    headerName: 'Razão Social',
    width: 210,
  },
];
