import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { ptBR as coreptBR } from '@mui/material/locale';
export let mainTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1266,
        xl: 1536,
      },
    },
    direction: 'ltr',
    mixins: {
      toolbar: {
        minHeight: 60,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    palette: {
      primary: {
        light: '#FFFF',
        main: '#565656',
        dark: '#000000',
        contrastText: '#ffff',
        textColor: '#fff',
        success: '#228B22',
      },
      secondary: {
        light: '#757ce8',
        main: '#ffffff',
        dark: '#002884',
        contrastText: '#263238',
      },
    },
  },
  ptBR,
  coreptBR
);
mainTheme = responsiveFontSizes(mainTheme);
