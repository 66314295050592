import React, { useState } from 'react';
import Details from './Details/Details.jsx';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import moment from 'moment';
import {
  Stack,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  TableFooter,
} from '@mui/material';
import { TablePaginationActions } from './TablePaginationActions/TablePaginationActions.jsx';
function Lista(props) {
  const { listaVendas, cancelaVenda, isMobile } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaVendas.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Stack
      sx={
        !isMobile
          ? {
              maxHeight: '85%',
              overflowY: 'auto',
              overflowX: 'hidden',
              maxWidth: '95%',
              margin: 0,
            }
          : { maxHeight: '85%', overflowY: 'auto' }
      }
    >
      <Table stickyHeader sx={{ maxWidth: '90%' }}>
        <TableHead>
          <TableCell></TableCell>
          <TableCell>Cód</TableCell>
          <TableCell>Data</TableCell>
          <TableCell>Valor</TableCell>
        </TableHead>
        <TableBody>
          {listaVendas
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((vendas, index) => {
              return (
                <>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => cancelaVenda(vendas)}
                      >
                        <AddCircleOutlineTwoToneIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell colSpan={1}>{vendas.codigo_venda}</TableCell>
                    <TableCell colSpan={1}>
                      {moment(vendas.emissao)
                        .add(3, 'hours')
                        .format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell colSpan={2}>
                      {' '}
                      {vendas.total_venda.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </TableCell>
                  </TableRow>
                  <Details
                    razao_social={vendas.razao_social}
                    vendedor={vendas.vendedor}
                  />
                </>
              );
            })}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={4}
            count={listaVendas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Stack>
  );
}
export default Lista;
