import React from 'react';

import {
  Stack,
  TextField,
  Input,
  IconButton,
  FormControl,
} from '@mui/material';
import { Search } from '@mui/icons-material';
function Header(props) {
  const {
    dataFinal,
    dataInicial,
    numPedido,
    totalGeral,
    handleChangeDataInicial,
    handleChangeDataFinal,
    handleChangeNumeroPedido,
    searchPedido,
  } = props;
  return (
    <Stack
      component="form"
      noValidate
      spacing={3}
      mb={1}
      sx={{ maxWidth: '90%' }}
    >
      <TextField
        id="date"
        label="Data Inicial"
        type="date"
        defaultValue={dataInicial}
        onChange={(e) => handleChangeDataInicial(e.target.value)}
        fullWidth
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        label="Data Final"
        type="date"
        size="small"
        defaultValue={dataFinal}
        onChange={(e) => handleChangeDataFinal(e.target.value)}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
        <Input
          id="numPedido"
          label="Numero Pedido"
          type="text"
          size="small"
          value={numPedido}
          sx={{ width: '95%' }}
          onChange={(e) => handleChangeNumeroPedido(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <IconButton onClick={() => searchPedido()}>
          <Search />
        </IconButton>
      </FormControl>
      <TextField
        id="total"
        label="TotalGeral"
        type="text"
        size="small"
        defaultValue={totalGeral.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
        disabled
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Stack>
  );
}

export default Header;
