import React from 'react';

import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';

import {
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Stack,
} from '@mui/material';

import { actionSX, avatarSX } from './styles';
import MainCard from '../../../../../components/Cards/MainCard';
import ActionAvatar from './Avatar';
import Details from './Details';

function ListaPedidos(props) {
  const confirm = useConfirm();
  const { pedidosAprovacao, liberaPedido } = props;

  function confirmaVenda(venda, liberado) {
    confirm({
      title: 'Confirme a Operação',
      description: `Deseja Liberar a Venda ${venda}`,
      cancellationText: 'Cancelar',
    })
      .then(() => {
        liberaPedido(venda, liberado);
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <MainCard sx={{ mt: 2 }} content={false}>
      {pedidosAprovacao.map((item, index) => {
        return (
          <List
            key={index}
            component="nav"
            sx={{
              px: 0,
              py: 0,
              '& .MuiListItemButton-root': {
                py: 1.5,
                '& .MuiAvatar-root': avatarSX,
                '& .MuiListItemSecondaryAction-root': {
                  ...actionSX,
                  position: 'relative',
                },
              },
            }}
          >
            <ListItemButton
              divider
              onClick={() => confirmaVenda(item.venda, item.liberado)}
            >
              <ListItemAvatar>
                <ActionAvatar status={item.liberado} />
              </ListItemAvatar>
              <ListItemText
                secondary={moment(item.emissao).format('DD/MM/YYYY')}
                primary={item.venda ? ` Pedido ${item.venda}` : <></>}
              />
              <ListItemSecondaryAction>
                {item.total ? (
                  <Stack alignItems="flex-end">
                    <Typography variant="subtitle1" noWrap>
                      {item.total.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                    <Typography variant="h6" color="primary.main" noWrap>
                      {item.loja}
                    </Typography>
                  </Stack>
                ) : (
                  <></>
                )}
              </ListItemSecondaryAction>
            </ListItemButton>
            <Details item={item} />
          </List>
        );
      })}
    </MainCard>
  );
}

export default ListaPedidos;
