import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { columns } from './Columns';
import { Typography } from '@mui/material';
import ModalDetalhesVendas from './ModalDetalhesVendas/ModalDetalhesVendas';
function GridVenda(props) {
  const { data } = props;
  const [openModalDetalhe, setOpenModalDetalhe] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState();
  const rows = data.map((item, index) => {
    return { id: index, codigo: item.codigo_cliente, nome: item.razao_social };
  });

  function closeModalDetalhe() {
    setOpenModalDetalhe(!openModalDetalhe);
  }
  function mostrarVendas(params) {
    setClienteSelecionado({
      codigo: params.row.codigo,
      razao_social: params.row.nome,
    });
    setOpenModalDetalhe(!openModalDetalhe);
  }

  return (
    <>
      {clienteSelecionado ? (
        <ModalDetalhesVendas
          open={openModalDetalhe}
          handleClose={closeModalDetalhe}
          cliente={clienteSelecionado}
        />
      ) : (
        <></>
      )}
      <Box sx={{ mb: 1.5 }}>
        <Typography variant="h3">Vendas a Liberar</Typography>
      </Box>
      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid
          autoHeight
          rows={rows}
          onRowClick={mostrarVendas}
          columns={columns}
          disableColumnFilter
          disableDensitySelector
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          pageSize={6}
          rowsPerPageOptions={[6]}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    </>
  );
}

export default GridVenda;
