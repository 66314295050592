import DoneAllIcon from '@mui/icons-material/DoneAll';

import { Avatar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function ActionAvatar({ status }) {
  let color;
  let title;
  let bgcolor;
  let icon;

  switch (status) {
    case 'NÃO':
      color = 'primary.warning';
      bgcolor = 'secondary.error';
      title = 'Aprovar';
      icon = <CheckCircleOutlineIcon />;
      break;
    case 'SIM':
      color = 'primary.success';
      bgcolor = 'secondary.dark';
      title = 'Aprovado';
      icon = <DoneAllIcon />;
      break;
    default:
      color = 'secondary';
      bgcolor = 'secondary.light.';
      title = 'Sem Status';
  }

  return (
    <Avatar alt={title} sx={{ bgcolor: bgcolor, color: color }}>
      {icon}
    </Avatar>
  );
}
