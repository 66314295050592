import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';
import { Button, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

function UserAvatar(props) {
  const { open, nome } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        height: '200px',
        ...(!open && { display: 'none' }),
      }}
    >
      <Stack direction="column" spacing={2}>
        <Avatar
          sx={{ bgcolor: deepOrange[500] }}
          alt={nome}
          src="/broken-image.jpg"
        />
      </Stack>
      <Typography variant="h6">Bem Vindo </Typography>
      <Typography component="span" fontSize="small">
        {nome}
      </Typography>

      <Button variant="contained" component={Link} to="/resetpassword">
        Trocar a Senha
      </Button>
      <Divider />
    </div>
  );
}

export default UserAvatar;
