import { ThemeProvider } from '@mui/material/styles';
import { mainTheme, darkTheme } from './themes/Themes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Routes from './routes/routes';
import { Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { GlobalStates, GlobalStatesContext } from './contexts/GlobalStates';
import history from './routes/history';
import Layout from './layouts/Layout';
import { ConfirmProvider } from 'material-ui-confirm';

function App() {
  return (
    <AuthProvider>
      <ConfirmProvider>
        <GlobalStates>
          <ThemeProvider theme={mainTheme}>
            <Router history={history}>
              <ToastContainer autoClose={3000} />
              <Routes />
            </Router>
          </ThemeProvider>
        </GlobalStates>
      </ConfirmProvider>
    </AuthProvider>
  );
}

export default App;
