import React, { createContext, useState } from 'react';
const GlobalStatesContext = createContext();

function GlobalStates({ children }) {
  const [currentTheme, setCurrentTheme] = useState('mainTheme');

  return (
    <GlobalStatesContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </GlobalStatesContext.Provider>
  );
}

export { GlobalStatesContext, GlobalStates };
