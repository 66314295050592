import { useState, useContext } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Avatar,
} from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { Context } from '../../contexts/AuthContext';

import Spinner from '../../components/Spinner/Spinner';

function Login() {
  const [values, setValues] = useState({
    email: '',
    senha: '',
  });

  const [nomeBotao, setNomeBotao] = useState('Entrar');
  const [error, setError] = useState({
    email: false,
    senha: false,
  });

  const { handleLogin, handleCheckUser, loading, setLoading } =
    useContext(Context);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBlur = (prop) => (event) => {
    if (values[prop] === '' || values[prop].isUndefined) {
      setError({ ...error, [prop]: true });
    } else {
      setError({ [prop]: false });
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (values.senha === '001') {
      handleCheckUser(values);
    } else if (values.email && values.senha) {
      handleLogin(values);
      setNomeBotao('Carregando...');
    } else {
      setLoading(false);
    }
  }
  if (loading) {
    return <Spinner />;
  }
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        //  <Spinner />
        <>
          <Grid
            container
            component="main"
            sx={{
              height: '97vh',

              margin: 0,
            }}
          >
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: 'url(/images/restricted_area.jpg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              sx={{ bgcolor: 'primary.textColor' }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Entrar no sistema
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="login"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('senha')}
                    label="Digite  o seu Login"
                    name="login"
                    autoComplete="login"
                    autoFocus
                    error={error.username}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="senha"
                    onChange={handleChange('senha')}
                    onBlur={handleBlur('senha')}
                    label="Digite sua Senha"
                    type="password"
                    id="senha"
                    error={error.password}
                    autoComplete="senha"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, color: 'primary.textColor' }}
                  >
                    <LockTwoToneIcon />
                    {nomeBotao}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2"></Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2"></Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Login;
