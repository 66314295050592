export const headCells = [
  {
    id: 'npedido',
    align: 'left',
    disablePadding: false,
    label: 'Num. Pedido',
  },
  {
    id: 'data',
    align: 'left',
    disablePadding: false,
    label: 'Data',
  },
  {
    id: 'total',
    align: 'left',
    disablePadding: false,
    label: 'Total',
  },

  {
    id: 'status',
    align: 'center',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'acao',
    align: 'center',
    disablePadding: false,
    label: 'Ação',
  },
];
