export const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
};

// action style
export const actionSX = {
  mt: 0.75,
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none',
};
