import React from 'react';

import { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import api from '../../api/api.js';
import Spinner from '../../components/Spinner/Spinner.jsx';
import { toast } from 'react-toastify';

import { Stack, Typography, useTheme, useMediaQuery } from '@mui/material';

import moment from 'moment';
import Lista from './Lista/Lista.jsx';
import Header from './Header/Header.jsx';

function Vendas() {
  const [loading, setLoading] = useState(true);
  const [listaVendas, setListaVendas] = useState();
  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-01'));
  const [dataFinal, setDataFinal] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );
  const [totalGeral, setTotalGeral] = useState();
  const [atualiza, setAtualiza] = useState();
  const [numPedido, setNumPedido] = useState();

  const theme = useTheme();

  const confirm = useConfirm();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    searchPedidoData();
  }, [dataInicial, dataFinal]);

  const searchPedidoData = () => {
    setLoading(true);
    api
      .post('/venda/periodoVendas', {
        data_inicio: dataInicial,
        data_fim: dataFinal,
      })
      .then(({ data }) => {
        setListaVendas(data);

        const valorInicial = 0;
        const somaTotal = data.reduce(
          (accumulator, currentValue) => accumulator + currentValue.total_venda,
          valorInicial
        );
        setTotalGeral(somaTotal);
      })
      .catch(() => {
        toast.error('Erro ao buscar dados ');
      })
      .finally(() => setLoading(false));
  };
  const searchPedido = () => {
    if (numPedido) {
      setLoading(true);
      api
        .post('/venda/vendasPedido', {
          pedido: numPedido,
        })
        .then(({ data }) => {
          setListaVendas(data);

          const valorInicial = 0;
          const somaTotal = data.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.total_venda,
            valorInicial
          );
          setTotalGeral(somaTotal);
        })
        .catch(() => {
          toast.error('Erro ao buscar dados ');
        })
        .finally(() => setLoading(false));
    } else {
      searchPedidoData();
    }
  };

  const handleChangeDataInicial = (e) => {
    setDataInicial(e);
  };
  const handleChangeDataFinal = (e) => {
    setDataFinal(e);
  };
  const handleChangeNumeroPedido = (e) => {
    setNumPedido(e);
  };

  function cancelaVenda(venda) {
    confirm({
      title: 'Cancela venda',
      description: 'Deseja cancelar essa Venda ?',
      cancellationText: 'Cancelar',
    })
      .then(() => {
        cancelBX(venda.codigo_venda, venda.codigo_empresa);
      })
      .catch(() => {
        /* ... */
      });
  }

  async function cancelBX(idVenda, idEmpresa) {
    await api
      .post('/venda/cancelaVenda', {
        id_venda: idVenda,
        id_empresa: idEmpresa,
      })
      .then(({ data }) => {
        setAtualiza(!atualiza);
        toast.success('Venda cancelada com sucesso. ');
      })
      .catch(() => {
        toast.error('Erro ao buscar dados ');
      });
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Stack spacing={2} mb={1.5} sx={{ margin: 0 }}>
        <Typography variant="h5">Histórico de Vendas</Typography>
      </Stack>
      <Header
        dataFinal={dataFinal}
        dataInicial={dataInicial}
        numPedido={numPedido}
        totalGeral={totalGeral}
        handleChangeDataFinal={handleChangeDataFinal}
        handleChangeDataInicial={handleChangeDataInicial}
        handleChangeNumeroPedido={handleChangeNumeroPedido}
        searchPedido={searchPedido}
      />
      {listaVendas && listaVendas.length > 0 ? (
        <Lista
          listaVendas={listaVendas}
          isMobile={isMobile}
          cancelaVenda={cancelaVenda}
        />
      ) : (
        <Typography variant="h6">Nenhum registro encontrado!</Typography>
      )}
    </>
  );
}

export default Vendas;
