import React from 'react';
import { useEffect, useState } from 'react';

import api from '../../api/api.js';
import Spinner from '../../components/Spinner/Spinner.jsx';
import { toast } from 'react-toastify';

import GridVenda from './GridVenda/GridVenda.jsx';

function LiberaVenda() {
  const [loading, setLoading] = useState(true);
  const [pedidosAprovacao, setPedidosAprovacao] = useState();

  useEffect(() => {
    setLoading(true);
    async function searchData() {
      await api
        .post('cliente/aguardandoLiberacao')
        .then(({ data }) => {
          setPedidosAprovacao(data);
        })
        .catch(() => {
          toast.error('Erro ao buscar dados ');
        })
        .finally(() => setLoading(false));
    }
    searchData();
  }, []);

  return loading ? <Spinner /> : <GridVenda data={pedidosAprovacao} />;
}

export default LiberaVenda;
