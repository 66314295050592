import React, { useEffect, useState } from "react";

import {
  Grid,
  Box,
  TextField,
  Typography,
  Stack,
  Collapse,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";

import DadosInicais from "../../components/Cards/DadosIniciais/DadosInicias.js";

import api from "../../api/api.js";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid";

import MainCard from "../../components/Cards/MainCard.js";
import Spinner from "../../components/Spinner/Spinner.jsx";

function HomePage() {
  const [loading, setLoading] = useState(false);

  const [openReceber, setOpenReceber] = useState(false);
  const [openRecebidas, setOpenRecebidas] = useState(false);
  const [openAtrasados, setOpenAtrasados] = useState(false);
  const [openTotalVendas, setOpenTotalVendas] = useState(false);

  const [totalVendas, setTotalVendas] = useState();
  const [atrasados, setAtrasados] = useState();
  const [contasAReceber, setContasAReceber] = useState();
  const [contasRecebidas, setContasRecebidas] = useState();

  const [dataInicialAReceber, setDataInicialAReceber] = useState(
    moment().format("YYYY-MM-01")
  );
  const [dataFinalAReceber, setDataFinalAReceber] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [dataInicialRecebidas, setDataInicialRecebidas] = useState(
    moment().format("YYYY-MM-01")
  );
  const [dataFinalRecebidas, setDataFinalRecebidas] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [dataInicialVendas, setDataInicialVendas] = useState(
    moment().format("YYYY-MM-01")
  );
  const [dataFinalVendas, setDataFinalVendas] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  useEffect(() => {
    async function buscaVendasMensal() {
      await api
        .post("/venda/totalizador", {
          data_inicio: dataInicialVendas,
          data_fim: dataFinalVendas,
          empresa: "",
          codigo_cliente: 0,
          vendedor: "",
        })
        .then(({ data }) => {
          setTotalVendas(data[0].vendas + data[1].vendas);
        })
        .catch(() => toast.error("Erro ao Carregar Dados"));
    }
    buscaVendasMensal();
  }, [dataInicialVendas, dataFinalVendas]);

  useEffect(() => {
    async function buscaContasAReceber() {
      await api
        .post("/financeiro/contasAReceberTotalizador", {
          data_inicio: dataInicialAReceber,
          data_fim: dataFinalAReceber,
          codigo_cliente: 0,
        })
        .then(({ data }) => {
          setContasAReceber(data[0].total);
        })
        .catch(() => toast.error("Erro ao Carregar Dados"));
    }
    buscaContasAReceber();
  }, [dataInicialAReceber, dataFinalAReceber]);

  useEffect(() => {
    async function buscaContasRecebidas() {
      await api
        .post("/financeiro/contasRecebidasTotalizador", {
          data_inicio: dataInicialRecebidas,
          data_fim: dataFinalRecebidas,
          codigo_cliente: 0,
        })
        .then(({ data }) => {
          setContasRecebidas(data[0].total);
        })
        .catch(() => toast.error("Erro ao Carregar Dados"));
    }
    buscaContasRecebidas();
  }, [dataInicialRecebidas, dataFinalRecebidas]);

  useEffect(() => {
    async function buscaAtrasados() {
      await api
        .post("/financeiro/contasAReceberTop")
        .then(({ data }) => {
          setAtrasados(data);
        })
        .catch(() => toast.error("Erro ao Carregar Dados"));
    }
    buscaAtrasados();
  }, []);

  if (loading) {
    return <Spinner />;
  }
  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ mb: { maxWidth: "80vw" } }}
    >
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" mb={2}>
            Período
          </Typography>
          <IconButton
            onClick={() => setOpenTotalVendas(!openTotalVendas)}
            sx={{ padding: 1 }}
          >
            {openTotalVendas ? (
              <GridArrowUpwardIcon />
            ) : (
              <GridArrowDownwardIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={openTotalVendas} timeout="auto" unmountOnExit>
          <Stack component="form" noValidate spacing={3} mb={1}>
            <TextField
              id="date"
              label="Data Inicial"
              type="date"
              defaultValue={dataInicialVendas}
              onChange={(e) => setDataInicialVendas(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="Data Final"
              type="date"
              defaultValue={dataFinalVendas}
              onChange={(e) => setDataFinalVendas(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Collapse>
        <DadosInicais
          title="Total Vendas"
          count={
            totalVendas != null ? (
              totalVendas.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            ) : (
              <CircularProgress color="success" />
            )
          }
          percentage=""
          color="warning"
          extra=" "
          textExtra1=" "
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" mb={2}>
            Período
          </Typography>
          <IconButton
            onClick={() => setOpenReceber(!openReceber)}
            sx={{ padding: 1 }}
          >
            {openReceber ? <GridArrowUpwardIcon /> : <GridArrowDownwardIcon />}
          </IconButton>
        </Box>
        <Collapse in={openReceber} timeout="auto" unmountOnExit>
          <Stack component="form" noValidate spacing={3} mb={1}>
            <TextField
              id="date"
              label="Data Inicial"
              type="date"
              defaultValue={dataInicialAReceber}
              onChange={(e) => setDataInicialAReceber(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="Data Final"
              type="date"
              defaultValue={dataFinalAReceber}
              onChange={(e) => setDataFinalAReceber(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Collapse>
        <DadosInicais
          title="Total a Receber"
          count={
            contasAReceber != null ? (
              contasAReceber.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            ) : (
              <CircularProgress color="success" />
            )
          }
          percentage=""
          color="success"
          extra=" "
          textExtra1=" "
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" mb={2}>
            Período
          </Typography>
          <IconButton
            onClick={() => setOpenRecebidas(!openRecebidas)}
            sx={{ padding: 1 }}
          >
            {openRecebidas ? (
              <GridArrowUpwardIcon />
            ) : (
              <GridArrowDownwardIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={openRecebidas} timeout="auto" unmountOnExit>
          <Stack component="form" noValidate spacing={3} mb={1}>
            <TextField
              id="date"
              label="Data Inicial"
              type="date"
              defaultValue={dataInicialRecebidas}
              onChange={(e) => setDataInicialRecebidas(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="Data Final"
              type="date"
              defaultValue={dataFinalRecebidas}
              onChange={(e) => setDataFinalRecebidas(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </Collapse>
        <DadosInicais
          title="Total Recebidas"
          count={
            contasRecebidas != null ? (
              contasRecebidas.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            ) : (
              <CircularProgress color="success" />
            )
          }
          percentage=""
          color="success"
          extra=" "
          textExtra1=" "
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" mb={2}>
            TOP 10 Enroscos
          </Typography>
          <IconButton
            onClick={() => setOpenAtrasados(!openAtrasados)}
            sx={{ padding: 1 }}
          >
            {openAtrasados ? (
              <GridArrowUpwardIcon />
            ) : (
              <GridArrowDownwardIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={openAtrasados} timeout="auto" unmountOnExit>
          <Stack component="form" noValidate spacing={3} mb={1}>
            <MainCard>
              {atrasados ? (
                atrasados.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        textAlign: "center",
                        borderBottom: "solid 1px",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          padding: "1px",
                          marginBottom: "1px",
                        }}
                      >
                        {item.razao_social}
                      </Typography>
                      <Typography
                        color={"error"}
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          fontWeight: 600,
                          padding: "1px",
                          marginBottom: "1px",
                        }}
                      >
                        {item.total.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                      <Divider variant="middle" sx={{ height: 2 }} />
                    </Box>
                  );
                })
              ) : (
                <CircularProgress />
              )}
            </MainCard>
          </Stack>
        </Collapse>
      </Grid>
    </Grid>
  );
}

export default HomePage;
