import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import GridCliente from './GridCliente/GridCliente';
import Spinner from '../../components/Spinner/Spinner';
import { toast } from 'react-toastify';
function Clientes() {
  const [listaClientes, setListaClientes] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function buscaClientes() {
      await api
        .post('/cliente/consulta')
        .then(({ data }) => {
          setListaClientes(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
    buscaClientes();
  }, [loading]);
  if (loading) {
    return <Spinner />;
  }

  async function liberacarteira(id) {
    setLoading(true);
    const codigo = id.row.codigo;
    await api
      .post('/cliente/liberaCompraCarteira', {
        codigo_cliente: codigo,
      })
      .then(({ data }) => {
        toast.success('Carteira liberada com sucesso.');
      })
      .catch(() => {
        toast.error('Erro ao buscar dados ');
      })
      .finally(() => setLoading(false));
  }
  return <GridCliente data={listaClientes} liberacarteira={liberacarteira} />;
}

export default Clientes;
