import { useConfirm } from 'material-ui-confirm';

// material-ui
import { Box, Table, TableBody, TableContainer } from '@mui/material';

// ==============================|| ORDER TABLE ||============================== //
import OrderTableHead from './Header';
import Details from './Details';

export default function TabelaPedidos(props) {
  const { pedidosAprovacao, liberaPedido } = props;

  const confirm = useConfirm();

  function confirmaVenda(venda, liberado) {
    console.log(venda);
    confirm({
      title: 'Confirme a Operação',
      description: `Deseja Liberar a Venda ${venda}`,
      cancellationText: 'Cancelar',
    })
      .then(() => {
        liberaPedido(venda, liberado);
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <Box>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          '& td, & th': { whiteSpace: 'nowrap' },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            '& .MuiTableCell-root:first-child': {
              pl: 2,
            },
            '& .MuiTableCell-root:last-child': {
              pr: 3,
            },
          }}
        >
          <OrderTableHead />
          <TableBody>
            {pedidosAprovacao.map((row, index) => {
              return (
                <>
                  <Details item={row} confirmaVenda={confirmaVenda} />
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
