import * as React from 'react';
import { useTheme } from '@mui/material/styles';

import {
  IconButton,
  Divider,
  Typography,
  Toolbar,
  CssBaseline,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { AppBar, Drawer, DrawerHeader } from './DrawerComponents';
import ItemListDrawer from './ItemListDrawer/ItemListDrawer';

import UserAvatar from './UserAvatar/UserAvatar';

export default function HeaderDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Bem Vindo ao Sistema
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <UserAvatar open={open} nome={'Emporio'} />
        <Divider />
        <ItemListDrawer />
      </Drawer>
    </>
  );
}
