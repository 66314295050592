import React, { useState } from 'react';

import { Typography, Stack, Collapse, Box, IconButton } from '@mui/material';
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from '@mui/x-data-grid';

export default function Details(props) {
  const { item } = props;
  const [openDetails, setOpenDetails] = useState(false);
  return (
    <Stack>
      <IconButton
        onClick={() => setOpenDetails(!openDetails)}
        sx={{ padding: 1 }}
      >
        {openDetails ? <GridArrowUpwardIcon /> : <GridArrowDownwardIcon />}
      </IconButton>
      <Collapse
        in={openDetails}
        timeout="auto"
        unmountOnExit
        collapsedSize={'100px'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 1,
            }}
          >
            <Box sx={{ width: '35px' }}>
              <Typography variant="caption">Cod.</Typography>
            </Box>
            <Box sx={{ width: '60%' }}>
              <Typography variant="caption">Descrição</Typography>
            </Box>
            <Box sx={{ width: '45px' }}>
              <Typography variant="caption">Qtde</Typography>
            </Box>
            <Box sx={{ width: '80px' }}>
              <Typography variant="caption">Unit.</Typography>
            </Box>
            <Box sx={{ width: '80px' }}>
              <Typography variant="caption">Total</Typography>
            </Box>
          </Box>
          {item.produto.map((produto, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 1,
                }}
                key={index}
              >
                <Box sx={{ width: '35px' }}>
                  <Typography variant="caption">
                    {produto.codigoProduto}
                  </Typography>
                </Box>
                <Box sx={{ width: '60%' }}>
                  <Typography variant="caption">
                    {produto.descricaoProduto}
                  </Typography>
                </Box>
                <Box sx={{ width: '45px' }}>
                  <Typography variant="caption">
                    {produto.quantidade}
                  </Typography>
                </Box>
                <Box sx={{ width: '80px', textAlign: 'left' }}>
                  <Typography variant="caption">
                    {produto.unitario.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
                <Box sx={{ width: '80px', textAlign: 'right' }}>
                  <Typography variant="caption">
                    {produto.total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Stack>
  );
}
