import React from "react";

import { useEffect, useState } from "react";

import api from "../../api/api.js";
import Spinner from "../../components/Spinner/Spinner.jsx";
import { toast } from "react-toastify";

import {
  Stack,
  TextField,
  Collapse,
  Typography,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

function Despesas() {
  const [loading, setLoading] = useState(true);
  const [listaDespesas, setListaDespesas] = useState();
  const [dataInicial, setDataInicial] = useState(moment().format("YYYY-MM-01"));
  const [totalGeral, setTotalGeral] = useState();

  const [dataFinal, setDataFinal] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(() => {
    setLoading(true);
    async function searchData() {
      await api
        .post("/financeiro/despesasPagas", {
          data_inicio: dataInicial,
          data_fim: dataFinal,
          codigo_empresa: 0,
        })
        .then(({ data }) => {
          setListaDespesas(data);
          const valorInicial = 0;
          const somaTotal = data.reduce(
            (accumulator, currentValue) => accumulator + currentValue.valor,
            valorInicial
          );
          setTotalGeral(somaTotal);
        })
        .catch(() => {
          toast.error("Erro ao buscar dados ");
        })
        .finally(() => setLoading(false));
    }
    searchData();
  }, [dataInicial, dataFinal]);

  function Details(props) {
    const [openDetails, setOpenDetails] = useState(false);
    const { historico, grupo, mov_n_nota } = props;

    return (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={() => setOpenDetails(!openDetails)}>
            {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        <Collapse in={openDetails} unmountOnExit timeout="auto">
          <Box width={"100%"} padding={1}>
            <Box>
              <Typography variant="subtitle2">Histórico</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{historico}</Typography>
            </Box>
          </Box>
          <Box width={"100%"} padding={1}>
            <Box>
              <Typography variant="subtitle2">Grupo</Typography>
            </Box>
            <Box>
              <Typography variant="caption">{grupo}</Typography>
            </Box>
          </Box>

          <Box width={"100%"} padding={1} borderBottom={"solid 0.3px #565656"}>
            <Box>
              <Typography variant="subtitle2">Nota</Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {mov_n_nota ? mov_n_nota : ""}
              </Typography>
            </Box>
          </Box>
        </Collapse>
      </>
    );
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Stack spacing={2} mb={1.5}>
        <Typography variant="h5">Despesas Pagas</Typography>
      </Stack>
      <Stack component="form" noValidate spacing={3} mb={1}>
        <TextField
          id="date"
          label="Data Inicial"
          type="date"
          defaultValue={dataInicial}
          onChange={(e) => setDataInicial(e.target.value)}
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="date"
          label="Data Final"
          type="date"
          size="small"
          defaultValue={dataFinal}
          onChange={(e) => setDataFinal(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="total"
          label="TotalGeral"
          type="text"
          size="small"
          defaultValue={totalGeral.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <Stack
        sx={
          !isMobile
            ? { maxHeight: "350px", overflowY: "auto" }
            : { maxHeight: "768px", overflowY: "auto" }
        }
      >
        {listaDespesas.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                position: "fixed",
                background: "#565656",
                color: "#ffffff",
                zIndex: 999,
              }}
            >
              <Box
                width={"18vw"}
                padding={1}
                borderBottom={"solid 0.3px #565656"}
              >
                <Typography variant="subtitle2">Cod.</Typography>
              </Box>

              <Box
                width={"27vw"}
                padding={1}
                borderBottom={"solid 0.3px #565656"}
              >
                <Typography variant="subtitle2">Data</Typography>
              </Box>
              <Box
                width={"30vw"}
                sx={{ display: "flex", justifyContent: "left" }}
                padding={1}
                borderBottom={"solid 0.3px #565656"}
              >
                <Typography variant="subtitle2">Valor</Typography>
              </Box>
            </Box>
            <Box height={45} marginBottom={5}></Box>
            {listaDespesas.map((despesa, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "Wrap",
                  }}
                >
                  <Box
                    width={"25%"}
                    padding={1}
                    borderBottom={"solid 0.3px #565656"}
                  >
                    <Box>
                      <Typography variant="caption">
                        {despesa.codigo}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={"40%"}
                    padding={1}
                    borderBottom={"solid 0.3px #565656"}
                  >
                    <Box>
                      <Typography variant="caption">
                        {moment(despesa.data)
                          .add(3, "hours")
                          .format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={"35%"}
                    padding={1}
                    borderBottom={"solid 0.3px #565656"}
                  >
                    <Box>
                      <Typography variant="caption">
                        {despesa.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <Details
                    historico={despesa.historico}
                    grupo={despesa.grupo}
                    mov_n_nota={despesa.mov_n_nota}
                  />
                </Box>
              );
            })}
          </>
        ) : (
          <Typography variant="h6">Nenhum registro encontrado!</Typography>
        )}
      </Stack>
    </>
  );
}

export default Despesas;
