import moment from 'moment';

import BotaoAcao from './BotaoAcao';
import OrderStatus from './OrderStatus';
// material-ui
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export default function Details(props) {
  const { item, confirmaVenda } = props;

  const [openDetails, setOpenDetails] = useState(false);
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" align="left">
          <Link color="primary">{item.venda}</Link>
        </TableCell>
        <TableCell align="left">
          {moment(item.emissao).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="left">
          {item.total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </TableCell>
        <TableCell align="left">
          <OrderStatus status={item.liberado} />
        </TableCell>
        <TableCell align="left">
          <BotaoAcao
            status={item.liberado}
            confirma={() => confirmaVenda(item.venda, item.liberado)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Codigo</TableCell>
                    <TableCell>Descricao</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Unitário</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.produto.map((produto) => (
                    <TableRow key={produto.codigoProduto}>
                      <TableCell component="th" scope="row">
                        {produto.codigoProduto}
                      </TableCell>
                      <TableCell>{produto.descricaoProduto}</TableCell>
                      <TableCell align="right">{produto.quantidade}</TableCell>
                      <TableCell align="right">
                        {produto.unitario.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {produto.total.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
