import { Switch, Route } from 'react-router-dom';

//Route personalizado para estabelecer a autenticacao tive que mudar o nome pois estava confundindo com o routes de react-router-dom
import { CustomRoute } from './CustomRoute';

//Pagina Login
import Login from '../pages/Login/Login';

import HomePage from '../pages/HomePage/HomePage';
import LiberaVenda from '../pages/LiberaVenda/LiberaVenda';
//Layout da Dashboard
import Layout from '../layouts/Layout';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Clientes from '../pages/Clientes/Clientes';
import Despesas from '../pages/Despesas/Despesas';
import ContasAReceber from '../pages/ContasAReceber/ContasAReceber';
import ContasRecebidas from '../pages/ContasRecebidas/ContasRecebidas';
import Vendas from '../pages/Vendas/Vendas';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Layout>
          <Switch>
            <CustomRoute isPrivate exact path="/" component={HomePage} />
            <CustomRoute
              isPrivate
              exact
              path="/clientes"
              component={Clientes}
            />
            <CustomRoute
              isPrivate
              exact
              path="/despesas"
              component={Despesas}
            />
            <CustomRoute
              isPrivate
              exact
              path="/areceber"
              component={ContasAReceber}
            />
            <CustomRoute
              isPrivate
              exact
              path="/liberavenda"
              component={LiberaVenda}
            />
            <CustomRoute
              isPrivate
              exact
              path="/recebidas"
              component={ContasRecebidas}
            />
            <CustomRoute
              isPrivate
              exact
              path="/vendas"
              component={Vendas}
            />
            <CustomRoute path="*" isPrivate />
          </Switch>
        </Layout>
      </Switch>
    </>
  );
}
