import React from 'react';
import { useContext } from 'react';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Button,
} from '@mui/material';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Context } from '../../../contexts/AuthContext';
import DescriptionIcon from '@mui/icons-material/Description';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import { GlobalStatesContext } from '../../../contexts/GlobalStates';
import { Link } from 'react-router-dom';

function ItemListDrawer() {
  const { handleLogout } = useContext(Context);

  return (
    <List>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/">
        <Tooltip arrow title="Home" placement="right">
          <ListItemIcon>
            <DashboardIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/clientes">
        <Tooltip arrow title="Clientes" placement="right">
          <ListItemIcon>
            <PeopleAltIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Clientes" />
      </ListItem>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/liberavenda">
        <Tooltip arrow title="Libera Vendas" placement="right">
          <ListItemIcon>
            <LockOpenIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Libera Vendas" />
      </ListItem>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/despesas">
        <Tooltip arrow title="Despesas" placement="right">
          <ListItemIcon>
            <ReceiptIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Despesas" />
      </ListItem>
      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/areceber">
        <Tooltip arrow title="Contas à Receber  " placement="right">
          <ListItemIcon>
            <DescriptionIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Contas à Receber" />
      </ListItem>

      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/recebidas">
        <Tooltip arrow title="Contas Recebidas  " placement="right">
          <ListItemIcon>
            <AdfScannerIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Contas Recebidas" />
      </ListItem>

      <ListItem sx={{ cursor: 'pointer' }} component={Link} to="/vendas">
        <Tooltip arrow title="Vendas  " placement="right">
          <ListItemIcon>
            <AttachMoneyIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Vendas" />
      </ListItem>

      <Divider />
      <ListItem component={Button} onClick={handleLogout}>
        <Tooltip arrow title="Sair " placement="right">
          <ListItemIcon>
            <ExitToAppTwoToneIcon color="primary.main" />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Sair" />
      </ListItem>
    </List>
  );
}

export default ItemListDrawer;
