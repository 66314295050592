import React, { useState } from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { columns } from './Columns';
import { Typography, IconButton, Box } from '@mui/material';
import ModalDetalhesCliente from './ModalDetalhesCliente/ModalDetalhesCliente';
import {
  ManageAccounts,
  SearchOffTwoTone,
  AddCircleOutlineTwoTone as AddCircleOutlineTwoToneIcon,
} from '@mui/icons-material';

function GridCliente(props) {
  const { data, liberacarteira } = props;
  const [openModalDetalhe, setOpenModalDetalhe] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState();

  const rows = data.map((item, index) => {
    return {
      id: index,
      codigo: item.codigo_cliente,
      nome: item.razao_social,
      libCart: item.lib_cart,
      liberado: item.liberado,
      cep: item.cep,
      endereco: item.endereco,
      numero: item.numero,
      bairro: item.bairro,
      complemento: item.complemento,
      estado: item.estado,
      cidade: item.cidade,
      telefones: item.telefones,
    };
  });

  function closeModalDetalhe() {
    setOpenModalDetalhe(!openModalDetalhe);
  }

  function mostrarDados(params) {
    setClienteSelecionado({
      codigo_cliente: params.row.codigo,
      razao_social: params.row.nome,
      lib_cart: params.row.libCart,
      liberado: params.row.liberado,
      cep: params.row.cep,
      endereco: params.row.endereco,
      numero: params.row.numero,
      bairro: params.row.bairro,
      complemento: params.row.complemento,
      estado: params.row.estado,
      cidade: params.row.cidade,
      telefones: params.row.telefones,
    });
    setOpenModalDetalhe(!openModalDetalhe);
  }

  const Acoes = ({ id }) => {
    return (
      <>
        <IconButton
          sx={{ margin: 1 }}
          color="primary"
          onClick={() => liberacarteira(id)}
        >
          <ManageAccounts />
        </IconButton>
        <IconButton sx={{ margin: 1 }}>
          <SearchOffTwoTone color="primary" onClick={() => mostrarDados(id)} />
        </IconButton>
      </>
    );
  };
  const newColumns = [
    {
      field: 'acao',
      headerName: '',
      width: 100,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes id={params} />
          </div>
        );
      },
    },
    ...columns,
  ];
  return (
    <>
      {clienteSelecionado ? (
        <ModalDetalhesCliente
          open={openModalDetalhe}
          handleClose={closeModalDetalhe}
          cliente={clienteSelecionado}
        />
      ) : (
        <></>
      )}
      <Box sx={{ mb: 1.5 }}>
        <Typography variant="h4">Clientes Cadastrados</Typography>
      </Box>
      <Box sx={{ height: 550, width: '97%' }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={newColumns}
          disableColumnFilter
          disableDensitySelector
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          pageSize={6}
          onCellClick={''}
          rowsPerPageOptions={[6]}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    </>
  );
}

export default GridCliente;
