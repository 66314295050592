import React, { useState } from 'react';
import {
  Collapse,
  Typography,
  Box,
  IconButton,
  TableRow,
  TableCell,
} from '@mui/material';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
export default function Details(props) {
  const { vendedor, razao_social } = props;
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell colSpan={12} align="center">
          <IconButton onClick={() => setOpenDetails(!openDetails)}>
            {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell colSpan={12}>
        <Collapse in={openDetails} unmountOnExit timeout="auto">
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">Vendedor</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">{vendedor}</Typography>
            </TableCell>

            <TableCell>
              <Typography variant="subtitle2">Cliente</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">{razao_social}</Typography>
            </TableCell>
          </TableRow>
        </Collapse>
      </TableCell>
    </>
  );
}
