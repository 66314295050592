import React, { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import {
  Box,
  Typography,
  Modal,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { style } from './styles.js';

import api from '../../../../api/api';
import TabelaPedidos from './TabelaPedidos/TabelaPedido.jsx';
import ListaPedidos from './ListaPedidos/ListaPedidos.jsx';
import { CloseTwoTone } from '@mui/icons-material';

function ModalDetalhesVendas(props) {
  const { open, handleClose, cliente } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [listaVendas, setListaVendas] = useState();
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState();
  const confirm = useConfirm();
  useEffect(() => {
    setLoading(true);
    async function buscaVendasCliente() {
      await api
        .post('/venda/orcamentoCliente', { codigo_cliente: cliente.codigo })
        .then(({ data }) => {
          setListaVendas(data);
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {
          setLoading(false);
          setRefreshData(false);
        });
    }
    buscaVendasCliente();
  }, [cliente, refreshData]);

  async function liberaPedido(codigo_venda, liberado) {
    if (liberado === 'SIM') {
      confirm({
        title: 'Operação não permitida',
        description: 'Venda já Liberada',
        cancellationText: 'Cancelar',
      });
    } else {
      setRefreshData(true);
      await api
        .post('/venda/liberaOrcamentoVenda', { codigo_venda: codigo_venda })
        .then()
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setRefreshData(false);
        });
    }
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <CircularProgress />
          ) : listaVendas ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseTwoTone color="error" />
                </IconButton>{' '}
                <Typography variant="subtitle1">
                  {cliente.razao_social}
                </Typography>
              </Box>
              {!isMobile ? (
                <ListaPedidos
                  pedidosAprovacao={listaVendas}
                  liberaPedido={liberaPedido}
                />
              ) : (
                <TabelaPedidos
                  pedidosAprovacao={listaVendas}
                  liberaPedido={liberaPedido}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ModalDetalhesVendas;
