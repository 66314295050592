import React, { useState } from 'react';

import {
  Box,
  Typography,
  Modal,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { style } from './styles.js';
import { CloseTwoTone } from '@mui/icons-material';

function ModalDetalhesClientes(props) {
  const { open, handleClose, cliente } = props;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <CircularProgress />
          ) : cliente ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={handleClose}>
                  <CloseTwoTone color="error" />
                </IconButton>
              </Box>

              <Box
                sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'Wrap' }}
              >
                <Box
                  width={'20%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Codigo:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.codigo_cliente}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'80%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Nome:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.razao_social}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'33%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Carteira:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.lib_cart}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  width={'33%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Liberado:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.liberado}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'33%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Cep:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{cliente.cep}</Typography>
                  </Box>
                </Box>
                <Box
                  width={'100%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Endereço:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.endereco}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'33%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Numero:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{cliente.numero}</Typography>
                  </Box>
                </Box>
                <Box
                  width={'66%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Complemento:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.complemento}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  width={'50%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Bairro:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{cliente.bairro}</Typography>
                  </Box>
                </Box>
                <Box
                  width={'50%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Cidade:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{cliente.cidade}</Typography>
                  </Box>
                </Box>
                <Box
                  width={'25%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Estado:</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{cliente.estado}</Typography>
                  </Box>
                </Box>
                <Box
                  width={'75%'}
                  padding={1}
                  borderBottom={'solid 0.3px #565656'}
                >
                  <Box>
                    <Typography variant="subtitle2">Telefones</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {cliente.telefones}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ModalDetalhesClientes;
