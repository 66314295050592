import React from 'react';

import { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';

import api from '../../api/api.js';
import Spinner from '../../components/Spinner/Spinner.jsx';
import { toast } from 'react-toastify';

import {
  Stack,
  TextField,
  Collapse,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function ContasRecebidas() {
  const [loading, setLoading] = useState(true);
  const [listaRecebidas, setListaRecebidas] = useState();
  const [totalGeral, setTotalGeral] = useState();
  const [atualiza, setAtualiza] = useState(false);

  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-01'));
  const [dataFinal, setDataFinal] = useState(
    moment().endOf('month').format('YYYY-MM-DD')
  );

  const confirm = useConfirm();

  useEffect(() => {
    setLoading(true);
    async function searchData() {
      await api
        .post('/financeiro/contasRecebidas', {
          data_inicio: dataInicial,
          data_fim: dataFinal,
          codigo_cliente: 0,
        })
        .then(({ data }) => {
          setListaRecebidas(data);

          const valorInicial = 0;
          const somaTotal = data.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.valor_liquido,
            valorInicial
          );
          setTotalGeral(somaTotal);
        })
        .catch(() => {
          toast.error('Erro ao buscar dados ');
        })
        .finally(() => setLoading(false));
    }
    searchData();
  }, [dataInicial, dataFinal, atualiza]);

  function cancelaBaixa(conta) {
    confirm({
      title: 'Cancela baixa',
      description: 'Deseja cancelar a Baixa ?',
      cancellationText: 'Cancelar',
    })
      .then(() => {
        cancelBX(conta.codigo_baixa);
      })
      .catch(() => {
        /* ... */
      });
  }

  async function cancelBX(idBaixa) {
    console.log(idBaixa);
    await api
      .post('/financeiro/cancelaBaixa', {
        id_baixa: idBaixa,
      })
      .then(({ data }) => {
        setAtualiza(!atualiza);
        toast.success('Baixa cancelada com sucesso. ');
      })
      .catch(() => {
        toast.error('Erro ao buscar dados ');
      });
  }

  function Details(props) {
    const [openDetails, setOpenDetails] = useState(false);
    const { despesa } = props;
    return (
      <>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            margin: 0,
          }}
        >
          <IconButton onClick={() => setOpenDetails(!openDetails)}>
            {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        <Collapse in={openDetails} unmountOnExit timeout="auto">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderBottom: 'solid 0.3px #565656',
              marginBottom: 5,
            }}
          >
            <Box width={'100%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Nome</Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  {despesa.razao_social}
                </Typography>
              </Box>
            </Box>
            <Box width={'100%'} padding={1}>
              <Box>
                <Typography variant="subtitle2">Referente</Typography>
              </Box>
              <Box>
                <Typography variant="caption">{despesa.referente}</Typography>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </>
    );
  }

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Stack spacing={2} mb={1.5}>
        <Typography variant="h5">Contas Recebidas</Typography>
      </Stack>
      <Stack component="form" noValidate spacing={3} mb={1}>
        <TextField
          id="date"
          label="Data Inicial"
          type="date"
          defaultValue={dataInicial}
          onChange={(e) => setDataInicial(e.target.value)}
          fullWidth
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="date"
          label="Data Final"
          type="date"
          size="small"
          defaultValue={dataFinal}
          onChange={(e) => setDataFinal(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="total"
          label="TotalGeral"
          type="text"
          size="small"
          defaultValue={totalGeral.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
          disabled
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <Stack sx={{ maxHeight: '350px', overflowY: 'auto' }}>
        {listaRecebidas.length > 0 ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                position: 'fixed',
                background: '#565656',
                color: '#ffffff',
                zIndex: 999,
              }}
            >
              <Box
                width={'25vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Data</Typography>
              </Box>

              <Box
                width={'20vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Valor</Typography>
              </Box>
              <Box
                width={'30vw'}
                padding={1}
                borderBottom={'solid 0.3px #565656'}
              >
                <Typography variant="subtitle2">Cliente</Typography>
              </Box>
            </Box>
            <Box height={45} marginBottom={5}></Box>
            {listaRecebidas.map((recebidas, index) => {
              const arrPrimeiroNome = recebidas.razao_social.split(' ');

              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'Wrap',
                  }}
                >
                  <Box width={'7%'}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => cancelaBaixa(recebidas)}
                    >
                      <AddCircleOutlineTwoToneIcon />
                    </IconButton>
                  </Box>

                  <Box
                    width={'25%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box>
                      <Typography variant="caption">
                        {moment(recebidas.data_baixa)
                          .add(3, 'hours')
                          .format('DD/MM/YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'30%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box>
                      <Typography variant="caption">
                        {recebidas.valor_liquido.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'35%'}
                    padding={1}
                    borderBottom={'solid 0.3px #565656'}
                  >
                    <Box>
                      <Typography variant="caption">
                        {arrPrimeiroNome[0]}
                      </Typography>
                    </Box>
                  </Box>
                  <Details despesa={recebidas} />
                </Box>
              );
            })}
          </>
        ) : (
          <Typography variant="h6">Nenhum registro encontrado!</Typography>
        )}
      </Stack>
    </>
  );
}

export default ContasRecebidas;
