// project import

import { Stack, Typography } from '@mui/material';
import Dot from '../../../../../components/Dots/Dot';

export default function OrderStatus({ status }) {
  let color;
  let title;

  switch (status) {
    case 'NÃO':
      color = 'warning';
      title = 'Pendente';
      break;
    case 'SIM':
      color = 'success';
      title = 'Aprovado';
      break;

    default:
      color = 'primary';
      title = 'Sem Status';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
}
